.fusionContent_table_head_tableFixedCol {
	position: sticky;
	left: 0;
	width: 20vw;
}

.fusionContent_table_head_row_cell {
	white-space: nowrap;
	min-width: 20vw;
}

.fusionContent_table th,
.fusionContent_table td {
	border-right: 2px solid rgb(0 0 0 / 23%);
}

.fusionContent_table td {
	min-width: 20vw;
}
