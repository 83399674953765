.pageContentContainer {
	flex-direction: row;
}

.pageContentContainer table tr div {
	width: auto !important;
}

.rightPannelContainer {
	display: flex;
	margin-top: 2.5rem;
	position: relative;
	min-width: 30vw;
}

.rightPannelContainerFullScreen {
	display: flex;
	margin-top: 2.5rem;
	position: relative;
	width: 100%;
}

.rightPannel {
	position: sticky;
	height: 98vh;
	/* width: 100%; */
	top: 1vh;
}

.pannelCardHeader {
	overflow: auto;
	white-space: pre-wrap;
}

.phoneCallMessageHiddenText {
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 12px !important;
}
